


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Application from '@/store/entities/registryoffices/application';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class ApplicationForm extends ListBase {
    entity: string = 'application';
    @Prop({ type: Boolean, default: false }) value: boolean;
    application: Application = new Application();
    modalShow: boolean = false;
    modalSetting: any = {
        entity: "application",
        titleFull: 'Solicitud 25%',
        saveText: 'Enviar Solicitud',
        saveIcon: 'send',
        width: (this.$vuetify.breakpoint.xsOnly ? '100%' : '700px'),
        showForm: false,
    };
               
    items = [
        this.gItem({ type: 'subheader', subheader: 'Opcion 1: Cuenta BCP de 14 digitos ejemplo: 21592318816033' }),
        this.gItem({ type: 'text', prop: 'account', required: true, prefix: 'Nro de Cuenta BCP:', size: 12 }),
        this.gItem({ type: 'divider' }),
        this.gItem({ type: 'subheader', subheader: 'Opcion 2: Otros Bancos/Cajas codigo de Cuenta Interbancaria de 20 digitos ejemplo: 00221784231991473521' }),
        this.gItem({ type: 'text', prop: 'cci', required: true, prefix: 'Nro de CCI Banco/Caja:', size: 12 }),
    ];
           
    initForm() {
        this.application = Util.extend(true, {}, this.$store.state.application.editModel); 
    }

    get user() {
        return this.$store.state.session.user;
    }

    async save() {
        if ((this.application.account == '' && this.application.cci == '') ||
            this.application.account == null && this.application.cci == null) {
            Util.abp.notify.success(this, 'Debe ingresar alguna de sus cuentas!');
            return;
        }

        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.application); 
            
            model.lawyer_id = this.user.id;
            model.lawyer_code = this.user.code;
            
            let result = await this.$store.dispatch({
                type: this.entity + '/' + (this.application.id > 0 ? 'update' : 'create'),
                data: model
            });
            this.$emit('save-success');
            this.cancel();

            if (this.application.id > 0 )
                Util.abp.notify.success(this, 'Tu Solicitud ha sido enviada!');
            else
                Util.abp.notify.success(this, 'Tu Solicitud ha sido actualizada!');
        } 
    }        

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {       

    }

    created() {
    }
}
