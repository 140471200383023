import { render, staticRenderFns } from "./form.vue?vue&type=template&id=5d3f60a3&"
import script from "./form.vue?vue&type=script&lang=ts&"
export * from "./form.vue?vue&type=script&lang=ts&"
import style0 from "./form.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAlert,VSubheader})
