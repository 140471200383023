





































































































































































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import Util from '@/lib/util';
import url from '@/lib/url'
import ImageModal from '@/components/image.vue';
import ApplicationForm from './form.vue';

@Component({
    components: { ImageModal, ApplicationForm }
})
export default class Applications extends ListBase {
    imgModalShow: boolean = false;
    modalShow: boolean = false;
    stepProc: number = 1;
    image: any = null;

    get loading() {
        return this.$store.state.lawyer.loading;
    }
    get uploading() {
        return this.$store.state.application.uploading;
    }
    get application() {
        return this.$store.state.lawyer.application;
    }
    get user() {
        return this.$store.state.session.user;
    }
    get title() {
        return (this.application == null ? 'Solicitar 25%' : 'Informaci&oacute;n de su solicitud de 25%');
    }
    get state() {
        if (this.application == null) return {};
        return Util.abp.list.getItem(this.application.state_id);
    }
    get states() {
        return Util.abp.list.getList('E_APPLICATION');
    }
    get formatUrl() {
        return url + Util.abp.setting.get('FORMAT1_URL');
    }
    get fileUrl() {
        return url + this.application.image_url;
    }
    get disabled() {
        return this.image == null;
    }
    get refinanciado() {
        return this.user.condition_message.indexOf('refinancia') >= 0;
    }
    get availDate() {
        if (this.user == null) return false;
        if (this.user.active) return true;
        if (this.refinanciado) return false;

        let date = this.user.condition_message.split(':');
        let parts = date[1].split('-');
        
        return parts[0] > 2019 || (parts[0] == 2019 && parts[1] >= 10);
    }

    create() {
        this.$store.commit('application/new');
        this.modalShow = !this.modalShow;
    }
    edit() {
        this.$store.commit('application/edit', this.application);
        this.modalShow = !this.modalShow;
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    onFileChange(file) {
        if (file != null) {
            
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = (e as any).target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async uploadImage() {
        if (this.image != null) {

            await this.$store.dispatch({
                type: 'application/uploadForm',
                data: {
                    id: this.application.id,
                    image: this.image
                }
            });
            this.stepProc = 3;
            Util.abp.notify.success(this, 'Formulario Subido Correctamente!');
        }
    }

    async loadData() {
        let result = await this.$store.dispatch({
            type: 'lawyer/getApplication',
            data: { id: Util.abp.session.userId }
            //data: { id: 9452 }                
        });
    }

    async mounted() {
        await this.loadData();
    }
}